
@import url('https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');


*{
 font-family: "Teachers";
}

body{
    min-height: 100vh;
    background: linear-gradient(106.5deg, rgba(255, 215, 185, 0.91) 23%, rgba(223, 159, 247, 0.8) 93%);
}

.card{
    height: 100%;
    transition: all ease 1s;
    &:hover{
        transform:scale(1.1);
        background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
    }
}
