@import "https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap";
* {
  font-family: Teachers;
}

body {
  background: linear-gradient(106.5deg, #ffd7b9e8 23%, #df9ff7cc 93%);
  min-height: 100vh;
}

.card {
  height: 100%;
  transition: all 1s;
}

.card:hover {
  background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  transform: scale(1.1);
}
/*# sourceMappingURL=index.67791179.css.map */
